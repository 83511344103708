import { computed, reactive, toRefs } from 'vue'
import { WeChatJS } from './interface'
// #ifdef H5
import 'jweixin-module'
// #endif
const APPID = import.meta.env.VITE_WX_APP_ID

const REDIRECT_URI = import.meta.env.VITE_WX_REDIRECT_URI

const RESPONSE_TYPE = 'code'

const SCOPE = 'snsapi_userinfo' // snsapi_base,snsapi_userinfo

const STATE = 'auth'

const AUTH_URI =
  'https://open.weixin.qq.com/connect/oauth2/authorize?${params}#wechat_redirect'

interface WeChatConfig {
  appid?: string;
  responseType?: string;
  scope?: string;
  state?: string;
}

/**
 *  微信接口
 * @returns
 */
export const useWeChat = () => {
  const state = reactive<{
    config: WeChatConfig;
  }>({
    config: {
      appid: APPID,
      responseType: RESPONSE_TYPE,
      scope: SCOPE,
      state: STATE,
    },
  })

  /**
   * 获取微信跳转地址
   */
  const getCode = (config?: WeChatConfig) => {
    if (config) {
      state.config = Object.assign({}, state.config, config)
    }
    const loginUrl = encodeURIComponent(window.location.origin + REDIRECT_URI)
    const params = `appid=${state.config.appid}&redirect_uri=${loginUrl}&response_type=${state.config.responseType}&scope=${state.config.scope}&state=${state.config.state}`
    const wxGetCodeUrl = AUTH_URI.replace('${params}', params)
    // location.href = wxGetCodeUrl
    location.replace(wxGetCodeUrl)
  }

  /**
   * 获取重定向地址
   */
  const getRedirectUri = computed(() => {
    return window.location.origin + REDIRECT_URI
  })

  const initPaymentJs = (data: WeChatJS.PaymentParams):Promise<boolean> => {
    return new Promise((resolve, reject) => {
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId: data.appId,
          timeStamp: data.timeStamp,
          nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
          package: data.package,
          signType: data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: data.paySign, // 支付签名
        },
        (res: any) => {
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            resolve(true)
          } else {
            reject(false)
          }
        }
      )
    })
  }

  return {
    ...toRefs(state),
    getCode,
    getRedirectUri,
    initPaymentJs
  }
}
